<template>
  <v-container
    class="pt-0"
    fluid
  >
    <div
      v-if="showTooltip"
      ref="svgTooltip"
      class="d-flex justify-center align-center pa-2"
      :style="`position: fixed; top: ${tooltipY}px; left: ${tooltipX}px;
            min-width: 100px; min-height: 50px; background: white; border-radius: 15px;
            opacity: .9; z-index: 1000;
          `"
    >
      <span>
        {{ tooltipText }}
      </span>
    </div>
    <v-row>
      <v-col
        class="px-0"
        cols="12"
      >
        <v-card
          class="mx-3"
        >
          <ewc-toolbar>
            <template v-slot:siteSelection>
              <v-col
                cols="4"
                md="3"
              >
                <v-select
                  id="ewcSiteSelectionDropdown"
                  v-model="selectedSite"
                  :items="sites"
                  label="Select Site"
                  item-text="siteName"
                  :loading="loadingSites"
                  return-object
                  class="mt-7"
                  outlined
                  dense
                  flat
                  @change=" (selection) => {
                    showAlert = false
                    floorplan = {}
                    grids = selection.siteGrids
                    if(grids.length === 1){
                      selectedFloor = grids[0]
                      ewcHolder.setLatestSiteAndFloorSelection({ site: selectedSite, floor: selectedFloor })
                      selectedRequestTable === 'Pending' ? getEquipmentEwcForSite(selectedFloor.id) : getCompletedRequestForSite(selectedFloor.id)
                    } else {
                      selectedFloor = ''
                    }
                  }
                  "
                />
              </v-col>
              <v-col
                cols="4"
                md="3"
              >
                <v-select
                  id="ewcFloorplanSelectionDropdown"
                  v-model="selectedFloor"
                  :disabled="loadingFloorplan || !selectedSite"
                  :loading="loadingFloorplan"
                  :items="grids"
                  label="Select Floorplan"
                  item-text="name"
                  return-object
                  class="mt-7"
                  outlined
                  dense
                  flat
                  @change=" (selection) => {
                    showAlert = false
                    floorplan = {}
                    ewcHolder.setLatestSiteAndFloorSelection({ site: selectedSite, floor: selectedFloor })
                    selectedRequestTable === 'Pending' ? getEquipmentEwcForSite(selection.id) : getCompletedRequestForSite(selection.id)
                  }"
                />
              </v-col>
            </template>
            <template v-slot:pageSpecificIcons>
              <v-btn
                color="primary"
                class="font-weight-bold"
                :text="selectedRequestTable === 'Completed'"
                :disabled="!selectedFloor"
                @click="
                  selectedRequestTable= 'Pending'
                  getEquipmentEwcForSite(selectedFloor.id)
                  selectedTableItem = null
                "
              >
                Pending
              </v-btn>
              <v-btn
                color="primary"
                class="font-weight-bold mr-2"
                :text="selectedRequestTable === 'Pending'"
                :disabled="!selectedFloor"
                @click="
                  selectedRequestTable = 'Completed'
                  getCompletedRequestForSite(selectedFloor.id)
                  selectedTableItem = null
                "
              >
                Completed
              </v-btn>
            </template>
          </ewc-toolbar>
        </v-card>
      </v-col>
      <v-col
        v-if="selectedFloor && selectedFloor.id"
        cols="12"
      >
        <v-card
          style="width: 100%; height: 100%"
          class="pa-2"
        >
          <template v-if="selectedRequestTable === 'Pending'">
            <v-data-table
              v-model="selectedTableItems"
              class="row-pointer"
              :headers="headers"
              single-select
              item-key="id"
              :loading="loadingFloorplan"
              loading-text="Retrieving Requests"
              :items="floorplan.equipmentRequests"
              :fixed-header="true"
              calculate-widths
              height="500"
              @click:row="
                (item, row) => {
                  row.select(true)
                  selectedTableItem = item
                }
              "
            >
              <template
                v-for="header in headers.filter((header) =>
                  header.hasOwnProperty('formatter')
                )"
                v-slot:[`item.${header.value}`]="{ value }"
              >
                {{ header.formatter(value) }}
              </template>
              <template v-slot:[`body.prepend`]>
                <tr>
                  <td>
                    <v-text-field
                      v-model="tableFieldTitle"
                      type="text"
                      class="pt-4"
                      outlined
                      dense
                      label="filter..."
                    />
                  </td>
                  <td>
                    <v-text-field
                      v-model="tableFieldOpenDate"
                      type="text"
                      class="pt-4"
                      outlined
                      dense
                    />
                  </td>
                  <td>
                    <v-text-field
                      v-model="tableFieldRequestingUser"
                      type="text"
                      class="pt-4"
                      outlined
                      dense
                    />
                  </td>
                </tr>
              </template>
            </v-data-table>
            <div class="d-flex mb-4 justify-center">
              <v-btn
                text
                class="font-weight-bold"
                :disabled="!selectedTableItem"
                :loading="loadingDescription"
                color="green"
                @click="openAcceptDialog"
              >
                Accept
              </v-btn>
              <v-btn
                text
                class="font-weight-bold"
                :disabled="!selectedTableItem"
                color="blue"
                @click="
                  selectedTableItem.equipmentToEditId ?
                    $router.push({ path: `/ewc/edit/${selectedTableItem.equipmentId}/${selectedFloor.id}/${selectedSite.siteId}/${selectedTableItem.id}/${selectedTableItem.equipmentToEditId}/` })
                    : $router.push({ path: `/ewc/requests/edit/${selectedTableItem.id}/${selectedTableItem.equipmentId}/${selectedFloor.id}/${selectedSite.siteId}/` })
                "
              >
                Edit
              </v-btn>
              <v-btn
                text
                class="font-weight-bold"
                :disabled="!selectedTableItem"
                color="red"
                @click="showDeleteRequestDialog = true"
              >
                Delete
              </v-btn>
            </div>
          </template>
          <template v-else>
            <v-data-table
              v-model="selectedTableItems"
              class="row-pointer"
              :headers="completedRequestHeaders"
              single-select
              :loading="loadingCompletedRequests"
              item-key="id"
              :items="completedRequestArray"
              :fixed-header="true"
              calculate-widths
              :server-items-length="totalCompletedRequests"
              height="500"
              @pagination="onChangePageCompletedRequests($event)"
              @click:row="
                (item, row) => {
                  row.select(true)
                  selectedTableItem = item
                }
              "
            >
              <!-- leaving this here for now, may use it later depending how we implement search/filter
              <template v-slot:[`body.prepend`]>
                <tr>
                  <template v-for="(value, name) in completedRequestTableSearchObject">
                    <td :key="name">
                      <v-text-field
                        type="text"
                        class="pt-4"
                        outlined
                        dense
                        @input="updateTableSearch($event, name)"
                      />
                    </td>
                  </template>
                </tr>
              </template> -->
            </v-data-table>
            <div class="d-flex mb-4 justify-center">
              <v-btn
                text
                class="font-weight-bold"
                :disabled="!selectedTableItem"
                color="green"
                @click="showCompletedRequestDialog = true"
              >
                View Request Details
              </v-btn>
            </div>
          </template>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        class="py-0"
      >
        <v-alert
          v-if="showAlert"
          type="error"
        >
          {{ alertMessage }}
        </v-alert>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showAcceptRequestDialog"
      width="900px"
      :persistent="acceptingRequest"
      @keydown.esc="(e) => {
        e.preventDefault()
        acceptingRequest ? '' : closeAcceptDialog()
      }"
      @click:outside="(e) => {
        e.preventDefault()
        acceptingRequest ? '' : closeAcceptDialog()
      }"
    >
      <v-card style="width: 900px;">
        <v-card-title
          class="text-h4 font-weight-bold grey--text"
        >
          Verify the following location and equipment information:
        </v-card-title>
        <v-sheet
          width="80%"
          style="margin-right: 10%; margin-left: 10%"
        >
          <v-row
            class="d-flex justify-center mt-2"
            style="height: 400px; width: 100%;"
          >
            <v-col
              class="pa-0"
              :cols="`${selectedRack ? 8 : 12}`"
              style="height: 400px; width: 100%;"
            >
              <grid-wrapper-minimap
                :local-bus="localBus"
                :floorplan="floorplan"
                :loading-floorplan="loadingFloorplan"
                :initial-state-object="initialStateObject"
                :pending-equipment-ewc="floorplan.pendingEquipmentEwc"
              />
            </v-col>
            <v-col
              v-if="selectedRack"
              class="pa-0"
              cols="4"
            >
              <v-row
                style="height: 100%"
              >
                <v-col
                  class="pt-6 pb-0 px-3"
                  cols="12"
                >
                  <v-card
                    class="my-0"
                    :style="`height: ${miniRackHeight}; width: 100%;`"
                  >
                    <ewc-rack-mini
                      :local-bus="localBus"
                      :rack-data="selectedRack"
                      :initial-state-object="initialStateObject"
                      :pending-equipment-ewc="floorplan.pendingEquipmentEwc"
                    />
                  </v-card>
                </v-col>
                <v-col
                  v-if="selectedEnclosure"
                  class="pa-3"
                  cols="12"
                >
                  <v-card
                    class="my-0"
                    :style="`height: ${miniEnclosureHeight}; width: 100%;`"
                  >
                    <ewc-enclosure-mini
                      :local-bus="localBus"
                      :enclosure-data="selectedEnclosure"
                      :initial-state-object="initialStateObject"
                      :pending-equipment-ewc="floorplan.pendingEquipmentEwc"
                    />
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <div class="d-flex flex-wrap justify-center my-4">
            <template v-for="(key) in Object.keys(requestDescription)">
              <div
                :key="key"
                :style="`background: ${requestDescription[key].includes('->') ? 'rgb(255, 226, 129, 0.75)' : 'rgba(180, 237, 224, 0.95)'};`"
                class="acceptDescription"
              >
                <span class="font-weight-bold">{{ key }}:&nbsp;</span> {{ key.includes('Date') ? requestDescription[key].includes('->') ? `${requestDescription[key].split('->')[0].split('T')[0]} -> ${requestDescription[key].split('->')[1].split('T')[0]}`: requestDescription[key].split('T')[0]: requestDescription[key] }}
              </div>
            </template>
          </div>
          <div class="d-flex pb-8 justify-center">
            <v-btn
              text
              class="font-weight-bold"
              :disabled="!selectedTableItem"
              color="green"
              :loading="acceptingRequest"
              @click="acceptRequest"
            >
              Accept
            </v-btn>
            <v-btn
              text
              class="font-weight-bold"
              :disabled="!selectedTableItem"
              color="blue"
              @click="
                selectedTableItem.equipmentToEditId ?
                  $router.push({ path: `/ewc/edit/${selectedTableItem.equipmentId}/${selectedFloor.id}/${selectedSite.siteId}/${selectedTableItem.id}/${selectedTableItem.equipmentToEditId}/` })
                  : $router.push({ path: `/ewc/requests/edit/${selectedTableItem.id}/${selectedTableItem.equipmentId}/${selectedFloor.id}/${selectedSite.siteId}/` })
              "
            >
              Edit
            </v-btn>
            <v-btn
              text
              class="font-weight-bold"
              :disabled="!selectedTableItem || acceptingRequest"
              color="grey"
              @click="closeAcceptDialog"
            >
              Cancel
            </v-btn>
          </div>
        </v-sheet>
      </v-card>
      <v-alert
        v-if="showAcceptAlert"
        :type="acceptAlertType"
        class="mt-2 mb-0"
      >
        {{ acceptAlertMessage }}
      </v-alert>
    </v-dialog>
    <v-dialog
      v-model="showDeleteRequestDialog"
      max-width="750"
    >
      <v-card
        v-if="selectedTableItem"
        class="pa-4 px-4"
      >
        <v-card-title class="text-h5 mb-3 justify-center">
          Are you sure you want to delete the request for: &nbsp; <span>{{ selectedTableItem.title + '?' }}</span>
        </v-card-title>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            id="deleteChartConfirmationButton"
            class="font-weight-bold"
            color="red darken-1"
            text
            :loading="deletingRequest"
            @click="deleteRequest"
          >
            Delete
          </v-btn>
          <v-btn
            color="grey darken-1"
            class="font-weight-bold"
            text
            @click="
              showDeleteRequestDialog = false
              showDeleteAlert = false
            "
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-alert
        v-if="showDeleteAlert"
        :type="deleteAlertType"
        class="mt-2 mb-0"
      >
        {{ deleteAlertMessage }}
      </v-alert>
    </v-dialog>
    <v-dialog
      v-if="selectedTableItem"
      v-model="showCompletedRequestDialog"
      width="900px"
    >
      <v-card style="width: 900px;">
        <v-card-title
          class="text-h4 font-weight-bold grey--text"
        >
          Completed Request Information:
        </v-card-title>
        <v-sheet
          width="80%"
          style="margin-right: 10%; margin-left: 10%"
        >
          <div class="d-flex flex-wrap justify-center my-4">
            <template v-for="(key) in Object.keys(selectedTableItem)">
              <div
                v-if="key !== 'id'"
                :key="key"
                style="background:rgba(180, 237, 224, 0.95);"
                class="acceptDescription"
              >
                <span class="font-weight-bold">{{ key }}:&nbsp;</span> {{ key.includes('Date') ? selectedTableItem[key].split('T')[0] : selectedTableItem[key] }}
              </div>
            </template>
          </div>
          <div class="d-flex pb-8 justify-center">
            <v-btn
              text
              class="font-weight-bold"
              color="grey"
              @click="showCompletedRequestDialog = false"
            >
              Close
            </v-btn>
          </div>
        </v-sheet>
      </v-card>
    </v-dialog>
    <material-snackbar
      v-model="showSnackBar"
      type="info"
      timeout="3000"
      class="ml-12"
      v-bind="{
        ['bottom']: true,
        ['left']: true
      }"
    >
      {{ snackBarMessage }}
    </material-snackbar>
  </v-container>
</template>

<script>
  import common from '@/api/common.js'
  import ewcHolder from '@/util/ewc-holder'
  import ewcApi from '@/api/ewc'
  import equipmentApi from '@/api/equipment'
  import Vue from 'vue'
  import messageHolder from '@/util/message-holder'
  import moment from 'moment'

  export default {
    name: 'EWCRequests',
    data: () => ({
      sites: [],
      grids: [],
      selectedSite: '',
      selectedFloor: '',
      loadingSites: false,

      tableFieldOpenDate: '',
      tableFieldTitle: '',
      tableFieldRequestingUser: '',

      floorplan: {},
      loadingFloorplan: false,

      selectedTableItem: null,

      showAcceptRequestDialog: false,
      localBus: new Vue(),
      selectedRack: null,
      selectedEnclosure: null,
      initialStateObject: {},

      showTooltip: false,
      tooltipY: 0,
      tooltipX: 0,
      tooltipText: '',

      acceptingRequest: false,
      showAcceptAlert: false,
      acceptAlertMessage: '',
      alertMessageGeneral: 'We were unable to complete your request at this time. Please try again later.',
      showAlert: false,
      alertMessage: '',
      acceptAlertType: 'error',

      showDeleteRequestDialog: false,
      deletingRequest: false,
      showDeleteAlert: false,
      deleteAlertMessage: '',
      alertMessageFailedPermissions: "Requests can only be accepted by a Site Admin. Please reach out to this site's admin to complete this request.",
      deleteAlertType: 'error',

      showSnackBar: false,
      snackBarMessage: '',
      snackBarMessageSuccess: 'Successfully approved request!',
      snackBarMessageDeleteSuccess: 'Successfully removed request!',

      ewcHolder: ewcHolder,

      loadingDescription: false,
      requestDescription: [],

      selectedRequestTable: 'Pending',

      completedRequestSkip: 0,
      completedRequestTake: 10,
      completedRequestArray: [],
      completedRequestTableSearchObject: {},
      loadingCompletedRequests: false,
      totalCompletedRequests: 0,
      showCompletedRequestDialog: false,

    }),
    computed: {
      headers () {
        return [
          {
            text: 'Title',
            value: 'title',
            filter: value => {
              if (!this.tableFieldTitle) return true
              return (value)?.toLowerCase().includes(this.tableFieldTitle.toLowerCase())
            },
          },
          {
            text: 'Open Date',
            value: 'openDate',
            formatter: (x) => moment(x).format('YYYY/MM/DD'),
            filter: value => {
              if (!this.tableFieldOpenDate) return true
              return (value)?.toLowerCase().includes(this.tableFieldOpenDate.toLowerCase())
            },
          },
          {
            text: 'Requesting User',
            value: 'username',
            filter: value => {
              if (!this.tableFieldRequestingUser) return true
              return value === parseInt(this.tableFieldRequestingUser)
            },
          },
        ]
      },
      completedRequestHeaders () {
        const array = []
        for (const item in this.completedRequestTableSearchObject) {
          array.push({
            text: `${item}`,
            value: `${item}`,
            width: '250px',
            filter: value => {
              if (!this.completedRequestTableSearchObject[item]) return true
              return (value)?.toLowerCase().includes(this.completedRequestTableSearchObject[item].toLowerCase())
            },
          })
        }
        return array
      },

      miniRackHeight () {
        if (!this.selectedEnclosure) {
          return '100%'
        } else {
          if (this.selectedEnclosure?.equipmentModel?.modelRows < 4) {
            return `${364 - this.selectedEnclosure.equipmentModel?.modelRows * 45}px`
          } else {
            return '182px'
          }
        }
      },
      miniEnclosureHeight () {
        if (!this.selectedEnclosure) return '0px'
        if (this.selectedEnclosure?.equipmentModel?.modelRows < 4) {
          return `${this.selectedEnclosure.equipmentModel?.modelRows * 45}px`
        } else {
          return '182px'
        }
      },

      selectedTableItems: {
        get: function () {
          return [this.selectedTableItem]
        },
        set (newValue) {
        },
      },
    },

    created () {
      ewcHolder.getDataCenters() ? this.sites = ewcHolder.getDataCenters() : this.getDataCentersForUser()
      if (ewcHolder.getLatestSiteAndFloorSelection()) {
        this.selectedSite = ewcHolder.getLatestSiteAndFloorSelection().site
        this.grids = this.selectedSite.siteGrids
        this.selectedFloor = ewcHolder.getLatestSiteAndFloorSelection().floor
      }

      if (this.selectedFloor) { this.getEquipmentEwcForSite(this.selectedFloor.id) }

      this.localBus.$on('display-tooltip', ({ e, label }) => {
        this.showTooltip = true
        this.tooltipY = e.clientY + 10
        this.tooltipX = e.clientX + 10
        this.tooltipText = label
      })
      this.localBus.$on('hide-tooltip', () => {
        this.showTooltip = false
      })
      if (messageHolder.getMessage()) {
        this.showSnackBar = true
        this.snackBarMessage = messageHolder.getMessage()
        messageHolder.setMessage('')
      }
    },
    methods: {
      getDataCentersForUser () {
        if (ewcHolder.getDataCenters()) {
          this.sites = ewcHolder.getDataCenters()
          if (this.sites.length === 1) {
            this.selectedSite = this.sites[0]
            this.grids = this.selectedSite.siteGrids
          }
          return
        }
        this.loadingSites = true
        ewcApi.getDataCentersForUser()
          .then(response => {
            console.log(response)
            this.sites = response.data
            if (this.sites.length === 1) {
              this.selectedSite = this.sites[0]
              this.grids = this.selectedSite.siteGrids
            }
            this.loadingSites = false
            ewcHolder.setDataCenters(response.data)
          })
          .catch(async error => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              this.getDataCentersForUser()
            } else {
              this.loadingSites = false
              this.showAlert = true
              this.alertMessage = this.alertMessageDataCenters
            }
          })
      },

      getEquipmentEwcForSite (gridId) {
        const floorplan = ewcHolder.getFloorplan()
        if (floorplan?.dataCenterGrid.id === parseInt(gridId)) {
          this.floorplan = floorplan
        } else {
          this.loadingFloorplan = true
          equipmentApi.getEquipmentEwcForSite(gridId)
            .then((response) => {
              console.log(response)
              this.loadingFloorplan = false
              this.$nextTick(() => { this.floorplan = response.data })
              ewcHolder.setFloorplan(response.data)
            })
            .catch(async (error) => {
              console.log(error)
              if (await common.handleBadCall(error, this.$router) === true) {
                this.getEquipmentEwcForSite(gridId)
              } else {
                this.loadingFloorplan = false
                this.showAlert = true
                this.alertMessage = this.alertMessageFloorplan
                this.selectedFloor = ''
              }
            })
        }
      },

      delay (time) {
        return new Promise(resolve => setTimeout(resolve, time))
      },
      async openAcceptDialog () {
        this.getEquipmentRequestDescription()
        while (this.loadingDescription) {
          await this.delay(100)
        }
        if (this.showAlert) return

        this.floorplan.pendingEquipmentEwc.forEach(pendingItem => {
          if (this.selectedTableItem.equipmentId === pendingItem.equipmentData.id) {
            this.initialStateObject = pendingItem
            if (this.initialStateObject.location) {
              // setup minimap showing the location for a new floor item
              if (!pendingItem.location.parentEquipmentEwcId) {
                let floorSelectionType = 'whole'
                const selectedPartialRectangles = []
                const selectedFloorRectangles = []
                let before = false
                let after = false

                if (this.initialStateObject.location.gridX % 1 !== 0 || this.initialStateObject.location.uwidth % 1 !== 0) {
                  floorSelectionType = 'half-left-right'
                  before = this.initialStateObject.location.gridX % 1 !== 0
                  after = (before && this.initialStateObject.location.uwidth % 1 === 0) ||
                    (!before && this.initialStateObject.location.uwidth % 1 !== 0)
                } else if (this.initialStateObject.location.gridY % 1 !== 0 || this.initialStateObject.location.uheight % 1 !== 0) {
                  floorSelectionType = 'half-up-down'
                  before = this.initialStateObject.location.gridY % 1 !== 0
                  after = (before && this.initialStateObject.location.uheight % 1 === 0) ||
                    (!before && this.initialStateObject.location.uheight % 1 !== 0)
                }

                if (floorSelectionType === 'half-left-right') {
                  for (let i = 0; i < this.initialStateObject.location.uwidth + (after ? 0.5 : 0); i++) {
                    for (let j = 0; j < this.initialStateObject.location.uheight; j++) {
                      if (i === 0 && before) {
                        selectedPartialRectangles.push({
                          x: this.initialStateObject.location.gridX,
                          y: this.initialStateObject.location.gridY + j,
                        })
                      } else if (i === Math.floor(this.initialStateObject.location.uwidth) && after) {
                        selectedPartialRectangles.push({
                          x: this.initialStateObject.location.gridX + this.initialStateObject.location.uwidth - 0.5,
                          y: this.initialStateObject.location.gridY + j,
                        })
                      } else {
                        selectedFloorRectangles.push({
                          x: this.initialStateObject.location.gridX + i - (before ? 0.5 : 0),
                          y: this.initialStateObject.location.gridY + j,
                        })
                      }
                    }
                  }
                } else if (floorSelectionType === 'half-up-down') {
                  for (let i = 0; i < this.initialStateObject.location.uwidth; i++) {
                    for (let j = 0; j < this.initialStateObject.location.uheight + (after ? 0.5 : 0); j++) {
                      if (j === 0 && before) {
                        selectedPartialRectangles.push({
                          x: this.initialStateObject.location.gridX + i,
                          y: this.initialStateObject.location.gridY,
                        })
                      } else if (j === Math.floor(this.initialStateObject.location.uheight) && after) {
                        selectedPartialRectangles.push({
                          x: this.initialStateObject.location.gridX + i,
                          y: this.initialStateObject.location.gridY + this.initialStateObject.location.uheight - 0.5,
                        })
                      } else {
                        selectedFloorRectangles.push({
                          x: this.initialStateObject.location.gridX + i,
                          y: this.initialStateObject.location.gridY + j - (before ? 0.5 : 0),
                        })
                      }
                    }
                  }
                } else {
                  for (let i = 0; i < this.initialStateObject.location.uwidth; i++) {
                    for (let j = 0; j < this.initialStateObject.location.uheight; j++) {
                      selectedFloorRectangles.push({
                        x: this.initialStateObject.location.gridX + i,
                        y: this.initialStateObject.location.gridY + j,
                      })
                    }
                  }
                }

                this.$nextTick(() => this.localBus.$emit('setMinimapFloorLocation', selectedFloorRectangles, selectedPartialRectangles, floorSelectionType))
                // setup minimap showing the location for a rack mounted item
              } else if (this.initialStateObject.equipmentModel.rackMounted) {
                const selectedRackRectangles = []
                this.floorplan.activeEquipmentEwc.concat(this.floorplan.pendingEquipmentEwc).forEach(potentialFloorItem => {
                  if (potentialFloorItem.equipmentData.id === this.initialStateObject.location.parentEquipmentEwcId) {
                    for (let i = 0; i < this.initialStateObject.location.uheight; i++) {
                      selectedRackRectangles.push(this.initialStateObject.location.gridY + i)
                    }
                    this.selectedRack = potentialFloorItem
                    this.$nextTick(() => this.localBus.$emit('setMinimapRackLocation', { selectedFloorItem: potentialFloorItem, selectedRackRectangles: selectedRackRectangles }))
                  }
                })
                // setup minimap showing the location for an enclosure item
              } else {
                this.floorplan.activeEquipmentEwc.concat(this.floorplan.pendingEquipmentEwc).forEach(potentialFloorItem => {
                  if (potentialFloorItem.equipmentData.rackLocation === this.initialStateObject.equipmentData.rackLocation && !potentialFloorItem.location.parentEquipmentEwcId) {
                    this.miniMapRack = this.selectedFloorItem
                    potentialFloorItem.children.concat(this.floorplan.pendingEquipmentEwc).forEach(potentialFloorItemChild => {
                      if (potentialFloorItemChild.equipmentData.id === this.initialStateObject.location.parentEquipmentEwcId) {
                        const selectedEnclosureRectangles = []
                        for (let i = 0; i < this.initialStateObject.location.uwidth; i++) {
                          for (let j = 0; j < this.initialStateObject.location.uheight; j++) {
                            selectedEnclosureRectangles.push({ x: this.initialStateObject.location.gridX + i, y: this.initialStateObject.location.gridY + j })
                          }
                        }
                        this.selectedRack = potentialFloorItem
                        this.selectedEnclosure = potentialFloorItemChild
                        this.$nextTick(() => this.localBus.$emit('setMinimapEnclosureLocation', { selectedFloorItem: potentialFloorItem, selectedEnclosure: potentialFloorItemChild, selectedRectangles: selectedEnclosureRectangles }))
                      }
                    })
                  }
                })
              }
            }
          }
        })
        this.showAcceptRequestDialog = true
      },

      acceptRequest () {
        this.acceptingRequest = true
        this.showAcceptAlert = false
        let skipFinallyBlock = false
        ewcApi.acceptEquipmentEwcRequest(this.selectedTableItem.id)
          .then((response) => {
            console.log(response)
            this.selectedTableItem = null
            this.showSnackBar = true
            this.snackBarMessage = this.snackBarMessageSuccess
            this.closeAcceptDialog()
            ewcHolder.setFloorplan(null)
            this.getEquipmentEwcForSite(this.selectedFloor.id)
          })
          .catch(async (error) => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              skipFinallyBlock = true
              this.acceptRequest()
            } else {
              this.showAcceptAlert = true
              if (error.response.status === 403) {
                this.acceptAlertType = 'info'
                this.acceptAlertMessage = this.alertMessageFailedPermissions
              } else {
                this.acceptAlertType = 'error'
                this.acceptAlertMessage = this.alertMessageGeneral
              }
            }
          })
          .finally(() => {
            if (!skipFinallyBlock) {
              this.acceptingRequest = false
            }
          })
      },

      closeAcceptDialog () {
        this.showAcceptRequestDialog = false
        this.selectedEnclosure = null
        this.selectedRack = null
        this.showAcceptAlert = false
        this.localBus.$emit('clearSelectedItems')
      },

      deleteRequest () {
        this.deletingRequest = true
        this.showDeleteAlert = false
        let skipFinallyBlock = false
        ewcApi.deleteEquipmentEwcRequest(this.selectedTableItem.id)
          .then((response) => {
            console.log(response)
            this.selectedTableItem = null
            this.showSnackBar = true
            this.snackBarMessage = this.snackBarMessageDeleteSuccess
            this.showDeleteRequestDialog = false
            ewcHolder.setFloorplan(null)
            this.getEquipmentEwcForSite(this.selectedFloor.id)
          })
          .catch(async (error) => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              skipFinallyBlock = true
              this.deleteRequest()
            } else {
              this.showDeleteAlert = true
              if (error.response.status === 403) {
                this.deleteAlertMessage = this.alertMessageFailedPermissions
                this.deleteAlertType = 'info'
              } else {
                this.deleteAlertMessage = this.alertMessageGeneral
                this.deleteAlertType = 'error'
              }
            }
          })
          .finally(() => {
            if (!skipFinallyBlock) {
              this.deletingRequest = false
            }
          })
      },

      getEquipmentRequestDescription () {
        this.loadingDescription = true
        this.showAlert = false
        let skipFinallyBlock = false
        ewcApi.getEquipmentRequestDescription(this.selectedTableItem.id)
          .then((response) => {
            console.log(response)
            this.requestDescription = {
              ...response.data,
              'Rack Location': response.data['Rack Location'].replace('null', 'None'),
              'Unit Location': response.data['Unit Location'].replace('null', 'None'),
              Bay: response.data.Bay.replace('null', 'None'),
            }
          })
          .catch(async (error) => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              skipFinallyBlock = true
              this.getEquipmentRequestDescription()
            } else {
              this.showAlert = true
              this.alertMessage = this.alertMessageGeneral
            }
          })
          .finally(() => { if (!skipFinallyBlock) { this.loadingDescription = false } })
      },

      // may use this for completedRequests search later on
      updateTableSearch (event, name) {
        Vue.set(this.completedRequestTableSearchObject, name, event)
      },

      getCompletedRequestForSite (id) {
        this.showAlert = false
        this.loadingCompletedRequests = true
        let skipFinallyBlock = false
        ewcApi.getCompletedRequestForSite(id, this.completedRequestSkip, this.completedRequestTake)
          .then((response) => {
            console.log(response)
            this.completedRequestArray = []
            this.totalCompletedRequests = response.data.count
            if (response.data.completedRequests.length === 0) return
            response.data.completedRequests.forEach(completedRequest => {
              this.completedRequestArray.push(
                {
                  id: completedRequest.id,
                  Title: completedRequest.title,
                  ...completedRequest.description,
                  'Open Date': moment(completedRequest.openDate).format('YYYY/MM/DD'),
                  'Requesting Username': completedRequest.requestingUsername,
                  'Closed Date': moment(completedRequest.closedDate).format('YYYY/MM/DD'),
                  'Accepting Username': completedRequest.acceptingUsername,
                },
              )
            })
            // Using the search object both for search and for building out table headers, using Vue.set to initialize properties being added to an object in order to make the properties reactive
            this.completedRequestTableSearchObject = {}
            Vue.set(this.completedRequestTableSearchObject, 'Title', '')
            Vue.set(this.completedRequestTableSearchObject, 'Open Date', '')
            Vue.set(this.completedRequestTableSearchObject, 'Requesting Username', '')
            Vue.set(this.completedRequestTableSearchObject, 'Closed Date', '')
            Vue.set(this.completedRequestTableSearchObject, 'Accepting Username', '')
            Object.keys(response.data.completedRequests[0].description).forEach(key => {
              Vue.set(this.completedRequestTableSearchObject, key, '')
            })
          })
          .catch(async (error) => {
            console.log(error)
            if (await common.handleBadCall(error, this.$router) === true) {
              skipFinallyBlock = true
              this.getCompletedRequestForSite(id)
            } else {
              this.showAlert = true
              this.alertMessage = this.alertMessageGeneral
            }
          })
          .finally(() => { if (!skipFinallyBlock) { this.loadingCompletedRequests = false } })
      },

      onChangePageCompletedRequests (event) {
        if (this.completedRequestSkip === event.pageStart && this.completedRequestTake === event.itemsPerPage) return
        this.completedRequestSkip = event.pageStart
        this.completedRequestTake = event.itemsPerPage === -1 ? event.itemsLength : event.itemsPerPage
        this.getCompletedRequestForSite(this.selectedFloor.id)
      },
    },

  }
</script>
<style css-scoped>
  .acceptDescription {
    border-style: solid;
    border-width: thin;
    border-radius: 5px;
    display: flex;
    align-content: center;
    padding: 5px 10px;
    margin: 5px;
  }
  tr.v-data-table__empty-wrapper {
    text-align: left;
  }
</style>
